<template class="mr-3">
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-8">
          <a-range-picker
            style="width: 100%"
            :placeholder="[$t('paiement.dateDebut'), $t('paiement.dateFin')]"
            format="DD/MM/YYYY"
            :mode="['date', 'date']"
            :value="monthRangeData"
            @panelChange="handlePanelChange"
            @change="handlePanelChange"
          />
        </div>
        <div class="col-md-2">
          <json-csv
            style="width: 100%"
            class="ant-btn"
            :label="{ serial: 'Numéro de série' }"
            :data="csvToExport"
            :name="'Declaration TVA.csv'"
            ><a-icon
              class="anticon mt-2"
              style="color: green"
              type="file-excel"
            /><span>CSV</span></json-csv
          >
        </div>
        <div class="col-md-2">
          <a-button style="width: 100%" type="file-pdf" @click="generatePdf">
            <a-icon type="file-pdf" @click="generatePdf" />
            {{ $t("action.imprimerTab") }}
          </a-button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div>
        <a-table
          @change="tableChanged"
          rowKey="_id"
          :pagination="true"
          :data-source="data"
          :columns="columns"
          :loading="tableLoading"
          :scroll="{ x: 'max-content' }"
        >
          <template slot="ModePaiement" slot-scope="text"
            >{{
              text == "espéce"
                ? $t("recette.espece")
                : text == "chèque"
                ? $t("recette.cheque")
                : text == "Chèque et espéce"
                ? $t("recette.chequeEtEspece")
                : text
            }}
          </template>

          <template slot="paymentOnWhat" slot-scope="text, record">
            <div class="d-inline">
              <a-tag color="blue" v-if="record.info.onInscription">{{
                $t("recette.inscription")
              }}</a-tag>
              <div v-if="record.info.months">
                <a-tag
                  color="blue"
                  v-for="(value, key) in record.info.months"
                  :key="key"
                  >{{ getMonthName(key) }}</a-tag
                >
              </div>
            </div>
          </template>
          <template slot="date" slot-scope="text">
            {{ moment(text).format("DD/MM/YYYY") }}
          </template>

          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <a-button
            slot="annulation"
            type="danger"
            slot-scope="text, record"
            @click="() => annulation(record)"
          >
            <a-icon type="delete" href="javascript:;" />{{
              $t("recette.annulation")
            }}
          </a-button>
          <a-button
            slot="print"
            type="primary"
            slot-scope="text, record"
            @click="() => print(record)"
          >
            <a-icon type="file-pdf" />{{ $t("action.imprimer") }}
          </a-button>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import JsPDF from "jspdf";
import "jspdf-autotable";
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import JsonCsv from "vue-json-csv";

export default {
  components: {
    JsonCsv,
  },
  async created() {
    await this.getTransactions();

    this.cacheData = this.data.map((item) => ({ ...item }));
    this.tableLoading = false;
    this.filtredTable = this.data.map((item) => ({ ...item }));
  },
  computed: {
    ...mapState(["settings"]),
    csvToExport() {
      const data = [...this.filtredTable];
      return data.map((item) => {
        let elems = [];
        if (item.info.months)
          for (const key of Object.keys(item.info.months)) {
            elems.push(this.getMonthName(key));
          }

        return {
          numeroDeSerie: item.serial,
          nomClient: item.nomClient,
          typeReglement: item.typeReglement,
          paiementSur: elems,
          modePaiement: item.modePaiement,
          montantHT: item.montantHT,
          montantTTC: item.montantTTC,
          datePaiement: moment(item.date).format("DD/MM/YYYY"),
        };
      });
    },
  },
  data() {
    return {
      monthRangeData: [],
      rowData: [],
      tableLoading: true,
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      columns: [
        //numéro de série
        {
          title: this.$t("recette.numSerie"),
          dataIndex: "serial",
          key: "serial",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "serial",
          },
          onFilter: (value, record) =>
            record.serial.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.nom"),
          dataIndex: "nomClient",
          key: "nomClient",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nomClient",
          },
          onFilter: (value, record) =>
            record.nomClient.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeReglement"),
          dataIndex: "typeReglement",
          key: "typeReglement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "typeReglement",
          },
          onFilter: (value, record) =>
            record.typeReglement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.paiementQuoi"),
          dataIndex: "paymentOnWhat",
          key: "paymentOnWhat",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "paymentOnWhat",
          },
          onFilter: (value, record) =>
            record.paymentOnWhat.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.modePaiement"),
          dataIndex: "modePaiement",
          key: "modePaiement",
          scopedSlots: {
            customRender: "ModePaiement",
          },
          onFilter: (value, record) =>
            record.typeReglement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantHTVA"),
          dataIndex: "montantHT",
          key: "montantHT",
          sorter: (a, b) => a.montantHT - b.montantHT,
        },
        {
          title: this.$t("recette.timbreFiscal"),
          dataIndex: "fiscal",
          key: "fiscal",
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "montantTTC",
          key: "montantTTC",
          sorter: (a, b) => a.montantTTC - b.montantTTC,
        },
        {
          title: this.$t("recette.datePaiement"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            moment(record.date).format("DD/MM/YYYY").includes(value),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
      filtredTable: [],
    };
  },
  methods: {
    moment,
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    getMonthName(monthString) {
      const months = {};
      const monthsArr = moment.months();
      for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];
      return monthString ? months[parseInt(monthString.split("-")[1])] : null;
    },
    handlePanelChange(value) {
      this.monthRangeData = value;
      if (value) {
        this.data = this.rowData.filter((elem) => {
          const start = moment(value[0]).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
          const end = moment(value[1]).set({
            hour: 24,
            minute: 59,
            second: 59,
            millisecond: 99,
          });
          return moment(elem.date).isBetween(start, end, undefined, "[]");
        });
        this.filtredTable = this.data;
      }
    },
    transactionFormater(transaction) {
      const name =
        transaction.typeAS == "A"
          ? transaction.dataType.designation
          : transaction.dataType.name;
      const typeName = transaction.typeAS == "A" ? "Activité" : "Scolarité";

      const parent = transaction.parent;

      return {
        serial: transaction.serial,
        _id: transaction._id,
        nomClient: `${parent.mother.firstName} ${parent.mother.lastName} ET ${parent.father.firstName} ${parent.father.lastName}`,
        typeReglement: `${typeName}/${name}`,
        modePaiement: transaction.receiptType.name,
        activity: transaction.activity,
        student: transaction.student,
        montantHT: transaction.montantHT,
        montantTTC: transaction.montantTotal,
        tva: Number(transaction.tva),
        fiscal: Number(transaction.fiscal),
        payment: transaction.payment,
        typeAS: transaction.typeAS,
        typeFR: transaction.typeFR,
        date: transaction.date,
        info: {
          onInscription: Boolean(transaction.onInscription),
          months: transaction.months,
        },
      };
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    generatePdf() {
      this.$gtag.event("Imp Déclaration TVA", {
        event_category: "Impression PDF",
        event_label: "declarations:Déclaration TVA",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Déclaration TVA";
      let filterText = "";
      if (this.monthRangeData[0])
        filterText =
          "De " +
          this.monthRangeData[0]._d.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
            day: "numeric",
          }) +
          " à " +
          this.monthRangeData[1]._d.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
            day: "numeric",
          });
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.text(50, 70, filterText);
      doc.setFontSize(10);

      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (error) {
        console.log(error);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      let sommeHT = 0;
      let sommeTTC = 0;
      let sommeTVA = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(
          this.filtredTable[i].student.firstName +
            " " +
            this.filtredTable[i].student.lastName
        );
        let frais = [];
        if (this.filtredTable[i].info.onInscription) frais.push("Inscription");
        if (this.filtredTable[i].info.months) {
          Object.keys(this.filtredTable[i].info.months).forEach((e) => {
            frais.push(this.getMonthName(e));
          });
        }

        arr.push(frais);
        arr.push(this.filtredTable[i].montantHT);
        sommeHT += parseFloat(this.filtredTable[i].montantHT);
        arr.push(
          (
            this.filtredTable[i].montantTTC -
            this.filtredTable[i].montantHT -
            this.filtredTable[i].fiscal
          ).toFixed(3)
        );
        sommeTVA += parseFloat(
          this.filtredTable[i].montantTTC -
            this.filtredTable[i].montantHT -
            this.filtredTable[i].fiscal
        );
        arr.push(this.filtredTable[i].montantTTC.toFixed(3));
        arr.push(this.filtredTable[i].montantTTC.toFixed(3));
        sommeTTC += parseFloat(this.filtredTable[i].montantTTC);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        styles: { font: "Amiri" },
        head: [
          ["Enfant", "Frais", "Montant HTVA", "Montant TVA", "Montant TTC"],
        ],
        body: array,
        foot: [
          [
            "",
            "Total : ",
            sommeHT.toFixed(3),
            sommeTVA.toFixed(3),
            sommeTTC.toFixed(3),
          ],
        ],
        showFoot: "lastPage",
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Déclaration_TVA.pdf");
    },
    async getTransactions() {
      await apiClient
        .post("/transaction/filter", {
          query: { status: { $ne: "cancelled" }, typeFR: "F" },
          aggregation: [
            {
              $lookup: {
                from: "payments",
                localField: "payment",
                foreignField: "_id",
                as: "payment",
              },
            },
            {
              $lookup: {
                from: "students",
                localField: "payment.student",
                foreignField: "_id",
                as: "student",
              },
            },
            {
              $lookup: {
                from: "parents",
                localField: "student.parent",
                foreignField: "_id",
                as: "parent",
              },
            },
            {
              $lookup: {
                from: "activities",
                localField: "payment.activity",
                foreignField: "_id",
                as: "activity",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "payment.classroom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $lookup: {
                from: "receipttypes",
                localField: "receiptType",
                foreignField: "_id",
                as: "receiptType",
              },
            },
            {
              $project: {
                _id: 1,
                typeAS: 1,
                typeFR: 1,
                montantTotal: 1,
                montantHT: 1,
                tva: 1,
                fiscal: 1,
                date: 1,
                serial: 1,
                months: 1,
                onTransReduction: 1,
                onInscription: 1,
                student: {
                  firstName: 1,
                  lastName: 1,
                  parent: 1,
                },
                parent: {
                  mother: 1,
                  father: 1,
                },
                activity: {
                  _id: 1,
                  designation: 1,
                  monthly: 1,
                  inscription: 1,
                },
                classroom: {
                  _id: 1,
                  name: 1,
                  monthly: 1,
                  inscription: 1,
                },
                payment: {
                  _id: 1,
                  defaultReduction: 1,
                  inscription: 1,
                  months: 1,
                  typeAS: 1,
                },
                receiptType: { name: 1 },
              },
            },
          ],
        })
        .then((res) => {
          this.data = res.data.map((elem) =>
            this.transactionFormater({
              ...elem,
              dataType:
                elem.typeAS == "A" ? elem.activity[0] : elem.classroom[0],
              payment: elem.payment[0],
              receiptType: elem.receiptType[0],
              student: elem.student[0],
              parent: elem.parent[0],
            })
          );
          this.rowData = [...this.data];
        })
        .catch();
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
</style>
